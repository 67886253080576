import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useConnectWallet } from "features/home/redux/hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomTable from "components/CustomTable/CustomTable.js";
import Pagination from '@material-ui/lab/Pagination';
import _ from "lodash";
import MeterialButton from '@material-ui/core/Button';
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { useFetchLeaderboardDetail, useFetchLeaderboardRank } from "./redux/hooks";
import Popover from '@material-ui/core/Popover';
import { useFetchPrice } from "../price/redux/hooks";
import Button from "components/CustomButtons/Button.js";
import moment from "moment";
import Tabs from "components/CustomTabs/Tabs.js";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 40,
    lineHeight: 1.1,
    color: "white",
  }, 
  popover: {
    pointerEvents: 'none',
  },
  dateText: {
    fontWeight: 700,
    fontSize: 34,
  },
  bidInfo: {
    backgroundColor: "rgba(255,255,255,0.2)",
    textAlign: "right",
    marginTop: 30,
    padding: 20,
    fontSize: 44,
    lineHeight: 1.1,
    fontWeight: 700,
  },
  bidSpan: {
    fontSize: 24,
    fontWeight: 500,
  },
  bidField: {
    backgroundColor: "#1E2025",
    marginTop: 50,
    padding: 20,
  },
  card: {
    flexGrow: 1,
    maxWidth: 400,
    verticalAlign: "middle",
    backgroundColor: "#1E2025",
    overflow: "hidden",
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 10,
    marginRight: 10,
    padding: "10px 20px",
    fontSize: 18,
  },
  cardSubTitle: {
    fontSize: 14,
    marginTop: 5,
  },
  timeSpan: {
    fontSize: 28,
    marginLeft: "5px",
  },
  time: {
    fontSize: 32,
    marginLeft: "5px",
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.5)",
    margin: "0 10px",
    padding: 3,
    textAlign: "center",
    verticalAlign: "middle",
  },

  grayText: {
    color: "rgba(255,255,255,0.6)",
  },
  heading: {
    fontSize: 20,
    color: "rgba(255,255,255,0.6)",
    textAlign: "right",
  },
  popPaper: {
    backgroundColor: theme.palette.background.paper,
    minWidth:100,
    padding:10
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "white",
  },
}))(LinearProgress);

const Leaderboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [rankNumber, setRankNumber] = useState("");
  const [selectedButton, setSelectedButton] = useState();
  const { web3, address } = useConnectWallet();
  const { fetchPrice, priceData } = useFetchPrice();
  const [limit, setLimit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = React.useState(1);
  const [dateButtons, setDateButtons] = React.useState([]);
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1);
  };
  //TODO: check project settings
  const name = "xyfinance"
  let startTime = 1650981600
  const duration = 3

  const handleChange = (event, value) => {
    setPage(value);
  };

  const { fetchLeaderboardDetail, fetchLeaderboardDetailPending, detail } =
    useFetchLeaderboardDetail();
  const { fetchLeaderboardRank, fetchLeaderboardRankPending, rank } =
    useFetchLeaderboardRank();

  const [anchorEl, setAnchorEl] = React.useState({});
  const handlePopoverOpen = (event, name, amount) => {
    setAnchorEl({target:event.currentTarget,name,amount});
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  const open = Boolean(anchorEl.target);
  const progressValue = rankNumber
    ? rankNumber < 26
      ? rankNumber < 11
        ? rankNumber < 5
          ? 100
          : 75
        : 50
      : 25
    : 0;

  useEffect(() => {
    if (web3 && address) {
      fetchLeaderboardDetail({ name, limit, timestamp: selectedButton, page, search,tabValue});
      fetchLeaderboardRank({ name, timestamp: selectedButton, search: address,tabValue});
      fetchPrice({web3});
      const id = setInterval(() => {
        fetchLeaderboardDetail({ name, limit, timestamp: selectedButton, page, search,tabValue});
        fetchLeaderboardRank({ name, timestamp: selectedButton, search: address,tabValue});
        fetchPrice({web3});
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address, name, limit, selectedButton, page, search,tabValue]);

  const handleChangeLimit = (newLimit) => {
    if (limit != newLimit) {
      setLimit(newLimit);
    }
  };

  useEffect(() => {
    // if (web3 && address) {
      let btns = []
      for(let i=1; i<=duration; i++) {
        let disabled = Math.floor(Date.now()/1000) < startTime
        if(!disabled) {
          setSelectedButton(startTime)
        }
        btns.push({ label: `Day ${i}`, value: startTime, disabled })
        startTime+=86400
      }

      setDateButtons(btns)
    // }
  }, []);

  useEffect(() => {
    if(detail.count) {
      setTotalCount(Number(detail.count))
    }
  }, [detail]);

  useEffect(() => {
    if(rank) {
      setRankNumber(_.get(rank, 'users.0.rank'), '')
    }
  }, [rank]);
  
  const leaderboard = detail.users ? detail.users : [];
  const listData = leaderboard
  
  // const listData = [{rank:1,address:"test"},{rank:2,address:"test"},{rank:3,address:"test"},{rank:900,address:"test"},{rank:1002,address:"test"}]
  // const listData = _.filter(leaderboard, function (o) {
  //   if (!search) return o;
  //   return _.startsWith(o.address, search);
  // });
  const changeInputValue = (event) => {
    let value = event.target.value;
    setSearch(value);
  };
  // const userRank = _.find(leaderboard, { address: address })
  //   ? parseInt(_.find(leaderboard, { address: address }).rank)
  //   : "";
  const renderRank = (r)=>{
    return(
    <span style={{border:"1px solid white", borderRadius:12,fontSize:34, padding:"0px 5px",  margin:'0 10px', paddingTop:10}}>
      #{r?r:"-"}
    </span>
    )
  }
  const renderTop = (r)=>{
    return(
    <span style={{border:"1px solid white", borderRadius:12,fontSize:18, padding:5, width:50, 
    display:'inline-block', textAlign:"left", margin:'0 10px', lineHeight:1.2}}>
        Top<br/>
        {r}%
    </span>
    )
  }
  const renderRewardIcon = (r)=>{
    return(
    <img src={r.image}   
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={(event)=>handlePopoverOpen(event,r.name,r.amount)}
      onMouseLeave={handlePopoverClose} 
      className="icon" />
    )
  }

  const renderReward = (r)=>{
    if(tabValue == 0){
      if(r < 2){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:240})]
      } 

      if(r < 3){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:210})]
      } 

      if(r < 4){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:180})]
      } 

      if(r < 5){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:150})]
      } 

      if(r < 6){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:120})]
      } 

      if(r < 11){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:90})]
      } 

      if(r < 26){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:60})]
      } 

      if(r < 51){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:30})]
      } 

      
    } else {
      if(r < 2){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:45})]
      } 

      if(r < 3){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:40})]
      } 

      if(r < 4){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:35})]
      } 

      if(r < 5){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:30})]
      } 

      if(r < 6){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:25})]
      } 

      if(r < 11){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:20})]
      } 

      if(r < 26){
        return [renderRewardIcon({name:"APECoin", image:require("assets/img/ape.png").default, amount:15})]
      } 
    }
  }
  const renderBoard = () =>{
    return(
      <>
       <Grid xs={12}>
          <div className="card" style={{ textAlign: "left" }}>
          <div style={{float:"right", margin:"15px 0", verticalAlign:"middle"}}>
              
              {renderRank(rankNumber)}
            </div>
            <h2>You : <span style={{fontSize:computer?20:13}}>{address}</span></h2>
            <div style={{marginTop:56}}>
            <BorderLinearProgress variant="determinate" value={progressValue} />
            </div>
          
            <Grid
              container
              spacing={3}
              style={{ margin: "5px", textAlign: "right" }}
            >
              <Grid item sm={2} />
              <Grid item sm={2}>
                <span>Top 25</span>
              </Grid>
              <Grid item sm={4}>
                <span>Top 10</span>
              </Grid>
              <Grid item sm={4}>
                <span>Top 5</span>
              </Grid>
            </Grid>
            <div style={{textAlign:"right"}}>
              <span style={{marginRight:10}}>Snapshot is taken every 24 hours.</span>
              <Link to={"/"}><Button color="secondary" >Stake</Button></Link>
            </div>
            
          </div>
        </Grid>

        <Grid xs={12} style={{ marginBottom: 20 }}>
          <div className="card">
            <Grid container alignItems="flex-end">
            
              <Grid item xs={12} sm={8}>
              <div style={{textAlign:"left"}}>
                {tabValue == 5 && dateButtons.map((button, index) => {
                return (
                  <MeterialButton key={button.label}
                    color="primary"
                    disabled={button.disabled}
                    variant={selectedButton == button.value ? "contained" : "outlined"}
                    style={{ margin: 5, fontSize: 15, padding:6 }}
                    onClick={() => {
                      setPage(1)
                      setSelectedButton(button.value)
                      
                    }}>
                    {button.label}
                  </MeterialButton>
                )
              })}
                {!search && <div style={{width:200}}><Pagination count={Math.ceil(totalCount/50)} 
                  color="primary" page={page} 
                  onChange={handleChange} showFirstButton showLastButton 
                  defaultPage={6} boundaryCount={2}/></div>}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomOutlinedInput
                  value={search}
                  placeholder="search address"
                  onClick={() => {}}
                  onChange={changeInputValue}
                />
              </Grid>
            </Grid>
        
          

            <CustomTable
              leftText={{}}
              headers={["Rank", `Address`, "Points", "Rewards"]}
              contents={listData.map((row, index) => {
                return [
                  `#${row.rank}`,
                  row.address,
                  `${tabValue == 0 ?row.totalDeposit:row.totalDeposit}`,
                  renderReward(row.rank),
                ];
              })}
            />
            <hr style={{margin:"30px 0"}}/>
            
          </div>
        </Grid>
      </>
    )
  }
  return (
    <div
      style={{
        position: "relative",
        margin: "0 auto",
        paddingTop: 40,
        maxWidth: 1000,
        minHeight: "100vh",
      }}
    >
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popPaper,
        }}
        open={open}
        anchorEl={anchorEl.target}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {anchorEl.name} : {anchorEl.amount}
      </Popover>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 className={classes.title}>Leaderboard</h1>
        </Grid>
        <Grid item xs={12}>
        <Tabs
            tabIndex={tabValue}
            handleChange={handleTabChange}
            tabs={[
              {label:"Overall",content:renderBoard()},
              {label:"Quarterly",content:renderBoard()}
            ]}
          />
        </Grid>


       
      </Grid>
    </div>
  );
};

export default Leaderboard;
