import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_REWARDS_DETAIL_BEGIN,
  FETCH_REWARDS_DETAIL_SUCCESS,
  FETCH_REWARDS_DETAIL_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import {
  stakingPoolABI,
  escrowedRewardABI,
  contracts,
  pools,
} from "../../configure";
import {
  convertAmountFromRawNumber,
} from "../../helpers/bignumber";

export function fetchRewardsDetail({ web3, address, data }) {    
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_REWARDS_DETAIL_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      const multicall = new MultiCall(web3, contracts.multicall.address);

      const singleTokenPoolContract = new web3.eth.Contract(
        stakingPoolABI,
        contracts.singleTokenPool.address
      );
      const lpPoolContract = new web3.eth.Contract(
        stakingPoolABI,
        contracts.lpPool.address
      );

      let calls = [];

      calls.push({ result: singleTokenPoolContract.methods.getTotalDeposit(address) });
      for (let reward of contracts.escrowedRewards) {
        calls.push({ result: singleTokenPoolContract.methods.withdrawableRewardsOf(reward.tokenAddress, address) });
      }

      calls.push({ result: lpPoolContract.methods.getTotalDeposit(address) })
      for (let reward of contracts.escrowedRewards) {
        calls.push({ result: lpPoolContract.methods.withdrawableRewardsOf(reward.tokenAddress, address) });
      }

      for (let reward of contracts.escrowedRewards) {
        const escrowedContract = new web3.eth.Contract(
          escrowedRewardABI,
          reward.escrowPool
        );
        calls.push({ result: escrowedContract.methods.getDepositsOf(address) });
      }

      multicall
        .all([calls])
        .then(async ([results]) => {
          const output = {};
          for (let pool of pools) {
            let i = pool.pid;
            output[i] = {
              stakedToken: results[i * 3].result
                ? convertAmountFromRawNumber(results[i * 3].result)
                : 0,
              claimableTokenRewards: []
            };
            contracts.escrowedRewards.forEach(function (reward, j) {
              output[i].claimableTokenRewards.push({
                token: reward.tokenAddress,
                name: reward.tokenName,
                amount: results[i * 3 + 1 + j].result
                  ? convertAmountFromRawNumber(results[i * 3 + 1 + j].result)
                  : 0
              })
            });
          }

          dispatch({
            type: FETCH_REWARDS_DETAIL_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch((error) => {
          console.log('error', error)

          dispatch({
            type: FETCH_REWARDS_DETAIL_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchRewardsDetail() {
  const dispatch = useDispatch();
  const { detail, fetchRewardsPending } = useSelector(
    (state) => ({
      fetchRewardsPending: state.reward.fetchRewardsPending,
      detail: state.reward.detail,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchRewardsDetail(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchRewardsDetail: boundAction,
    fetchRewardsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_REWARDS_DETAIL_BEGIN:
      return {
        ...state,
        fetchRewardsPending: true,
      };

    case FETCH_REWARDS_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.data,
        fetchRewardsPending: false,
      };

    case FETCH_REWARDS_DETAIL_FAILURE:
      return {
        ...state,
        fetchRewardsPending: false,
      };

    default:
      return state;
  }
}
