export const tokens = {
  basicRewardAddress: "0x77777777772cf0455fb38ee0e75f38034dfa50de",
  rewardTokens: [
    {
      token: "XY",
      address: "0x77777777772cf0455fb38ee0e75f38034dfa50de",
      decimals: 18,
      coingeckoId: "xy-finance",
      rewardPerSecond: 0,
    },
    {
      token: "FNC",
      address: "0x7f280dac515121dcda3eac69eb4c13a52392cace",
      decimals: 18,
      coingeckoId: "fancy-games",
      rewardPerSecond: 0,
    },
  ],
};
