export const contracts = {
  multicall: {
    address: "0x29de9dfa4adfe50c878fb2ffff8c1b28d9e24355",
  },
  escrowedRewards: [
    {
      tokenName: "XY",
      tokenAddress: "0x77777777772cf0455fB38eE0e75f38034dFa50DE",
      escrowPool: "0x8b8F14C34483bACF4dECebE0371C14F9b2DDaB4B",
      liquidityMiningManager: "0xd8e7C504d1Bcfeeb2192dE9f4a8ABC9F8522280C",
      view: "0x5d4f8Fd9298EB7ac17f151ef71eA5615444f26B1"
    },
    {
      tokenName: "FNC",
      tokenAddress: "0x7f280daC515121DcdA3EaC69eB4C13a52392CACE",
      escrowPool: "0xDDe5016BCd9B970d4629896054564CAC67B42D19",
      liquidityMiningManager: "0x2d51d1C4AA64DA748928dAb4e085800Fa380d5B5",
      view: "0x59F8862FA252932e14D152ab4DaDB7DBCa97fB4F"
    }
  ],
  singleTokenPool: {
    address: "0xE7983ac27B1F421a9968F811f330A6fCe8056cac",
  },
  lpPool: {
    address: "0x65c10D70253c9A28B6Cb4aFf976e5c3a0568D687",
  },
  uniswapLpPairAddress : {
    address: "0xbd7da348408f115c72c599af201c33ca3caef083"
  }
};
