import axios from "axios";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from './constants';
import { uniswapV2PairABI, contracts, tokens } from 'features/configure';
import { convertAmountFromRawNumber } from "../helpers/bignumber";
import _ from "lodash";

export function fetchPrice({ web3 }) {
  return dispatch => {
    dispatch({
      type: FETCH_PRICE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {

    const uniswapPairContract = new web3.eth.Contract(uniswapV2PairABI, contracts.uniswapLpPairAddress.address);

      let promises = [];
      for (let rewardToken of tokens.rewardTokens) {
        promises.push(axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: { ids: rewardToken.coingeckoId, vs_currencies: 'usd' },
        }))
      }

      promises.push(uniswapPairContract.methods.getReserves().call())
      promises.push(uniswapPairContract.methods.totalSupply().call())
      promises.push(axios.get(`https://analytics.vault.inc/api/stats/priceHistory?name=xyfinance`));
      promises.push(axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: { ids: "ethereum", vs_currencies: 'usd' },
        }))

      Promise.all(promises).then(data => {
        dispatch({
          type: FETCH_PRICE_SUCCESS,
          data
        });
        resolve(data);
      })  
        .catch(error => {
          dispatch({
            type: FETCH_PRICE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceDatas, lpData, chart, ethData } = useSelector(state => ({
    fetchPricePending: state.price.fetchPricePending,
    priceDatas: state.price.priceDatas, 
    lpData: state.price.lpData,
    chart: state.price.chart,
    ethData: state.price.ethData,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceDatas, 
    lpData,
    ethData,
    chart
  };
}

export function reducer(state = { fetchPricePending: false, priceDatas: {}, lpData: {},chart:[] }, action) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_SUCCESS:
      console.log(action.data);
      let priceDatas = {};
      tokens.rewardTokens.forEach(function (reward, j) {
        let price = action.data[j].data[reward.coingeckoId].usd
        priceDatas[reward.address] = price
      });

      let basicRewardPrice = priceDatas[tokens.basicRewardAddress];

      let chart = action.data[4].data

      let latest = _.last(chart);
      
      let modifiedLatest = (_.dropRight(latest));
      modifiedLatest.push(basicRewardPrice);
      
      let modifiedChart = (_.dropRight(chart));
      modifiedChart.push(modifiedLatest);

      const ethData = action.data[5].data['ethereum'].usd;

      const lpData = {
        tokenReserve: convertAmountFromRawNumber(action.data[2]._reserve0),
        baseReserve: convertAmountFromRawNumber(action.data[2]._reserve1,6),
        totalSupply: convertAmountFromRawNumber(action.data[3])
      }
      return {
        ...state,
        priceDatas: priceDatas,
        chart:modifiedChart,
        lpData: lpData,
        ethData: ethData,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    default:
      return state;
  }
}
