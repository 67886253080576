import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_DASHBOARD_BEGIN,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from "./constants";
import { contracts, pools, viewABI, liquidityMiningManagerABI } from "../../configure";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";
import BigNumber from "bignumber.js";
import { act } from "@testing-library/react";

export function fetchDashboard({ address, web3 }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_DASHBOARD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { web3 } = home;

      let promises = [];
      for (let reward of contracts.escrowedRewards) {
        const viewContract = new web3.eth.Contract(
          viewABI,
          reward.view
        );

        const liquidityManagerContract = new web3.eth.Contract(
          liquidityMiningManagerABI,
          reward.liquidityMiningManager
        );
        promises.push(viewContract.methods.fetchData(address).call());
        promises.push(liquidityManagerContract.methods.lastDistribution().call());
        promises.push(liquidityManagerContract.methods.rewardPerSecond().call());
      }


      Promise
      .all(promises)
      .then((results) => {
          dispatch({
            type: FETCH_DASHBOARD_SUCCESS,
            data: results,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: FETCH_DASHBOARD_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchDashboard() {
  const dispatch = useDispatch();

  const { detail, fetchDashboardPending } = useSelector(
    (state) => ({
      fetchDashboardPending: state.home.fetchDashboardPending,
      detail: state.home.detail,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchDashboard(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchDashboard: boundAction,
    fetchDashboardPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_BEGIN:
      return {
        ...state,
        fetchDashboardPending: true,
      };

    case FETCH_DASHBOARD_SUCCESS:
      let poolsInfo = [];
      for (let i =0; i < pools.length; i++) {
        let poolInfo = {}
        poolInfo["accountPendingRewards"] = []
        let timePassed = new Date()/1000 - action.data[1]
        for (let viewData of [action.data[0]]) {
          let pool = viewData ? viewData.pools[i] : [];
          poolInfo["accountTotalDeposit"] = convertAmountFromRawNumber(pool.accountTotalDeposit)
          poolInfo["accountEstRewards"] = new BigNumber(convertAmountFromRawNumber(action.data[2])).times(timePassed).times(pool.accountPoolShares).dividedBy(pool.totalPoolShares).toString();
          let pendingReward = {
            tokenAddress: viewData.escrowPool.depositToken,
            amount: convertAmountFromRawNumber(pool.accountPendingRewards)
          }
          poolInfo["accountPendingRewards"].push(pendingReward)
          poolInfo["deposits"] = pool.deposits
        }
        poolsInfo.push(poolInfo);
      }

      return {
        ...state,
        detail: {
          poolLength: pools.length,
          pools: poolsInfo,
        },
        fetchDashboardPending: false,
      };

    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        fetchDashboardPending: false,
      };

    default:
      return state;
  }
}
