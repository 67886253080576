export const pools = [
  {
    pid: 0,
    stakedTokenName: "XY",
    stakedTokenSymbol: "XY",
    rewardTokenName:"XY",
    stakedTokenDecimals: 18,
    tokenAddress: "0x77777777772cf0455fB38eE0e75f38034dFa50DE",
    poolAddress: "0xE7983ac27B1F421a9968F811f330A6fCe8056cac",
    getUrl:"https://app.uniswap.org/#/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0x77777777772cf0455fB38eE0e75f38034dFa50DE",
    weight: 0.2,
    toFixed: 2
  },
  {
    pid: 1,
    stakedTokenName: "XY:USDC LP",
    stakedTokenSymbol: "XY:USDC LP",
    rewardTokenName:"XY",
    stakedTokenDecimals: 18,
    tokenAddress: "0xbd7da348408f115c72c599af201c33ca3caef083",
    poolAddress: "0x65c10D70253c9A28B6Cb4aFf976e5c3a0568D687",
    getUrl:"https://app.uniswap.org/#/add/v2/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48/0x77777777772cf0455fB38eE0e75f38034dFa50DE?chain=mainnet",
    weight: 0.8,
    toFixed: 8
  },
];
